

.input{
    /* background-color: aqua; */
}
.input:-webkit-autofill {
    background-color: white !important;
}
.input:-webkit-autofill:hover, 
.input:-webkit-autofill:focus, 
.input:-webkit-autofill:active  {
    background: #fff !important;
}
.input:-internal-autofill-selecte {
    -webkit-text-fill-color: #000;
    background: #fff !important;
    box-shadow: inset 0 0 0 1px rgb(255 255 255 / 0%), inset 0 0 0 100px #fff;
}


/* .hola{
    background-color: aqua;
} */